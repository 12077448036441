import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* eslint-disable */
import { Formik, Form } from 'formik';
import { Col, Row, Button } from 'reactstrap';
import Alert from '../../general/Alert';
import SuccessModal from '../../general/SuccessModal';

import { apiAction } from '../../../../redux/actions/apiActions';
import setResourceData from '../../../../helpers/setResourceData';
import { toCamelCase, humanizeCamelCase } from '../../../../helpers/convertKeys';
import { getPresignedUrlAndUploadFileToS3 } from '../../../../helpers/imageHelper';

class FormWrapper extends Component {
  constructor() {
    super();
    this.state = {
      errors: [],
      serverErrors: {},
      formSuccess: false,
    };
  }

  handleClearServerErrors = () => {
    this.setState({ serverErrors: {}, errors: [] });
  }

  render() {
    const {
      customErrorMapper,
      validationSchema,
      requestMethod,
      successModalTitle,
      successModalMessage,
      successUrl,
      formTitle,
      formSubTitle,
      submitButtonText,
      dispatch,
      children,
      formUrlSuffix,
      initialValues,
      goBack,
      goBackText,
      history: domHistory,
    } = this.props;
    const { formSuccess, serverErrors, errors } = this.state;
    const { resource, resourceExtension } = setResourceData(domHistory);

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let params = values;

          if (values.files) {
            params = Object.keys(values).reduce((a, c) => (c === 'files' ? a : { ...a, [c]: values[c] }), {});
            const fileKeys = Object.keys(values.files);
            await Promise.all(
              // eslint-disable-next-line consistent-return
              fileKeys.map(async (fileKey) => {
                const file = values.files[fileKey];
                if (Object.keys(file).length) {
                  const result = await getPresignedUrlAndUploadFileToS3(file, fileKey);
                  return result;
                }
              }),
            )
              .then((r) => {
                const errs = r.filter(({ ok }) => !ok);
                if (errs.length) {
                  const fieldErrors = {};
                  const servErrors = {};
                  errs.forEach((e) => {
                    fieldErrors[toCamelCase(e.field || 'Error')] = e.message;
                    servErrors[toCamelCase(e.field || 'Error')] = e.message;
                  });
                  this.setState({ errors: errs });
                  setErrors(fieldErrors);
                } else {
                  r.forEach(({ data, fileKey }) => {
                  // assign our blob_signed_id's here to send to the backend
                    params[fileKey] = data;
                  });
                }
              })
              // eslint-disable-next-line no-console
              .catch((e) => console.log('Something bad happened', e));
          }

          // eslint-disable-next-line react/destructuring-assignment
          if (!this.state.errors.length) {
            dispatch(
              apiAction(
                {
                  resource,
                  method: requestMethod,
                  params,
                  url: `/${resourceExtension}${formUrlSuffix}`,
                  successHistoryPushTo: successUrl,
                  successTimeout: 2000,
                  history: domHistory,
                  onSuccess: () => {
                    this.setState({ formSuccess: true });
                    return { type: 'FORM_REQUEST' };
                  },
                  onFailure: (resErrors) => {
                    const fieldErrors = {};
                    const servErrors = {};
                    resErrors.forEach((e) => {
                      if (e.indices && e.indices.length > 0) {
                        fieldErrors[toCamelCase(e.group)] = {};
                        e.indices.forEach((i) => {
                          fieldErrors[toCamelCase(e.group)][i] = {};
                          fieldErrors[toCamelCase(e.group)][i][toCamelCase(e.field)] = e.message;
                        });
                      } else {
                        fieldErrors[toCamelCase(e.field || 'Error')] = e.message;
                      }
                      servErrors[toCamelCase(e.field || 'Error')] = e.message;
                    });
                    this.setState({ serverErrors: servErrors, errors: resErrors });
                    setErrors(fieldErrors);
                    return { type: 'FORM_REQUEST' };
                  },
                },
              ),
            );
            setSubmitting(false);
          }
        }}
      >
        {
          (props) => (
            <Form className="form">
              <SuccessModal
                isOpen={formSuccess}
                title={successModalTitle}
                colored
                btn="Success"
                message={successModalMessage}
              />
              <div className="col-md-12">
                <h3 className="page-title">
                  {formTitle}
                </h3>
                <h3 className="page-subhead subhead">
                  {formSubTitle}
                </h3>
              </div>
              {typeof children === 'function' ? children(props) : children}
              <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
                <Alert
                  color="danger"
                  className="alert--bordered"
                  icon
                  visible={Object.keys(serverErrors).length > 0}
                  onDismiss={this.handleClearServerErrors}
                >
                  <p>
                    <span className="bold-text mr-2">Warning!</span>
                    One or more errors are preventing this record from being saved!
                  </p>
                  {
                    customErrorMapper
                      ? customErrorMapper(errors)
                      : (
                        Object.keys(serverErrors).map((errorKey) => (
                          <p className="ml-2" key={`error-${errorKey}`}>
                            <b className="mr-1">{humanizeCamelCase(errorKey)}:</b>
                            { serverErrors[errorKey] }
                          </p>
                        ))
                      )
                  }
                </Alert>
                <Row>
                  <Col xs={6}>
                    <Button block color="secondary" disabled={props.isSubmitting} onClick={goBack || domHistory.goBack}>
                      { goBackText || 'Take Me Back'}
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button block type="submit" color="primary" disabled={props.isSubmitting}>
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )
        }
      </Formik>
    );
  }
}

FormWrapper.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  requestMethod: PropTypes.string,
  successModalTitle: PropTypes.string,
  successModalMessage: PropTypes.string,
  formTitle: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string,
  initialValues: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object,
  formUrlSuffix: PropTypes.string,
  successUrl: PropTypes.string,
  formSubTitle: PropTypes.string,
  goBack: PropTypes.func,
  goBackText: PropTypes.string,
  customErrorMapper: PropTypes.func,
  children: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
      PropTypes.func,
    ],
  ),
};

FormWrapper.defaultProps = {
  customErrorMapper: null,
  resourceitem: {},
  requestMethod: 'POST',
  successModalTitle: '',
  successModalMessage: '',
  submitButtonText: 'Submit',
  initialValues: {},
  formUrlSuffix: '',
  successUrl: '',
  formSubTitle: '',
  children: null,
  validationSchema: null,
  goBack: null,
  goBackText: '',
};

export default withRouter(connect()(FormWrapper));
