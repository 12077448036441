/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ReturnShipment = ({ exchangeReturnOrderId, exchangeReturnOrderShipments, orderId }) => {
  const trackingNumber = exchangeReturnOrderShipments && exchangeReturnOrderShipments[0] && exchangeReturnOrderShipments[0].trackingNumber;
  return (
    exchangeReturnOrderId
      ? (
        <div>
          <b className="mr-4">Return Shipment Tracking</b>
          {trackingNumber}
          {/* <span className="mx-2">|</span>
          <Link className="ml-1" to={`/orders/sales-shipments/${orderId}/remove-tracking`}>
            Drop
          </Link> */}
          
          {
            trackingNumber
              ? (
                <>
                  <span className="mx-2">|</span>
                  <Link className="ml-1" to={`/orders/sales-shipments/${exchangeReturnOrderId}/print-return-label`}>
                    Print Return Label
                  </Link>
                </>
              )
              : (
                <Link to={`/orders/sales-shipments/${exchangeReturnOrderId}/create-return-label`}>
                  Create Label
                </Link>
              )
          }
        </div>
      )
      : (
        <div>
          <b className="mr-4">Return Shipment</b>
          <Link to={`/orders/sales-orders/${orderId}/new-exchange`}>
            Add
          </Link>
        </div>
      )
  );
}

ReturnShipment.propTypes = {
  orderId: PropTypes.number,
  exchangeReturnOrderId: PropTypes.number,
};

ReturnShipment.defaultProps = {
  orderId: null,
  exchangeReturnOrderId: null,
};

export default ReturnShipment;
