
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ShippingWeight from './ShippingWeight';
import MailingContainer from './MailingContainer';
import TrackingNumber from './TrackingNumber';
import CarrierService from './CarrierService';
import PackingSlip from './PackingSlip';
import ReturnOrder from './ReturnOrder';

const ShippingColumn = (
  {
    shipment: {
      id,
      orderId,
      trackingNumber,
      shippingLabel,
      setWeight,
      calculatedWeight,
      mailingContainerName,
      mailingContainerId,
      shipBy,
      carrierServiceCode,
      orderSource,
      exchangeReturnOrderId,
      exchangeReturnOrderShipments,
      status,
    },
  },
) => (
  <span>
    <ReturnOrder
      orderId={orderId}
      exchangeReturnOrderId={exchangeReturnOrderId}
      exchangeReturnOrderShipments={exchangeReturnOrderShipments}
    />
    <PackingSlip id={id} />
    <ShippingWeight
      id={id}
      setWeight={setWeight}
      calculatedWeight={calculatedWeight}
    />
    <MailingContainer
      id={id}
      mailingContainerId={mailingContainerId}
      mailingContainerName={mailingContainerName}
    />
    <div><b className="mr-2">Ship By</b>{shipBy}</div>
    <CarrierService
      id={id}
      orderSource={orderSource}
      carrierServiceCode={carrierServiceCode}
    />
    <TrackingNumber
      id={id}
      trackingNumber={trackingNumber}
      shippingLabel={shippingLabel}
      status={status}
    />
  </span>
);

ShippingColumn.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.number,
    orderId: PropTypes.number,
    trackingNumber: PropTypes.string,
    shippingLabel: PropTypes.object,
    setWeight: PropTypes.number,
    calculatedWeight: PropTypes.number,
    mailingContainerName: PropTypes.string,
    mailingContainerId: PropTypes.number,
    shipBy: PropTypes.string,
    carrierServiceCode: PropTypes.string,
    requiresPackingSlip: PropTypes.bool,
    orderSource: PropTypes.shape(),
  }).isRequired,
};

export default ShippingColumn;
