/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ErrorMessage } from 'formik';

class MultiSelectField extends React.Component {
  static propTypes = {
    filterKey: PropTypes.string.isRequired,
    label: PropTypes.string,
    multiSelect: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType(
          [
            PropTypes.number,
            PropTypes.string,
          ],
        ),
        label: PropTypes.string,
      }),
    ).isRequired,
    placeholder: PropTypes.string,
    sorted: PropTypes.bool,
    value: PropTypes.oneOfType(
      [
        PropTypes.array,
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string,
        }),
      ],
    ),
  };

  static defaultProps = {
    multiSelect: false,
    onBlur: () => {},
    value: {},
    placeholder: '',
    label: '',
    sorted: true,
  }

  handleChange = (values) => {
    const updatedVal = values?.map(v => v.value) || [];
    const { onChange, filterKey } = this.props;
    onChange(filterKey, updatedVal.slice(0, 3));
  };

  handleBlur = () => {
    const { onBlur, filterKey } = this.props;
    onBlur(filterKey, true);
  };

  setValue = () => {
    const { value, options } = this.props;
    const filtered =  options.filter(v => value?.length && value.includes(v.value));
    return filtered.map(opt => ({label: opt.value, value: opt.value}))
  };

  orderedOptions = options => (
    options.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    })
  );

  render() {
    const {
      multiSelect,
      options,
      filterKey,
      placeholder,
      label,
      sorted,
    } = this.props;
    const optionsList = sorted ? this.orderedOptions(options) : options;
    const customStyles = {
      input: () => ({
        paddingBottom: 7,
      }),
    };
    const hasLabel = !!label;
    return (
      <div className="form__form-group" style={{ width: '100%' }}>
        { hasLabel ? <span className="form__form-group-label">{label}</span> : null }
        <div className="form__form-group-field">
          <Select
            id={`${filterKey}-select`}
            isMulti
            options={optionsList}
            multi={multiSelect}
            placeholder={placeholder}
            isClearable
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={this.setValue()}
            className="react-select"
            classNamePrefix="react-select"
            styles={customStyles}
          />
        </div>
        <ErrorMessage
          name={filterKey}
          component="div"
          className="login__error"
          style={{ marginTop: '6px' }}
        />
      </div>
    );
  }
}

export default MultiSelectField;
