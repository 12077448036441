import React, { useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import {
  Container, Col, Row, Button,
} from 'reactstrap';

import { getFilterables } from '../../redux/reducers/authReducer';
import SelectField from '../../shared/components/form/SelectField';
import ContentsArrayField from './components/form/ContentsArrayField';
import Alert from '../../shared/components/general/Alert';
import SuccessModal from '../../shared/components/general/SuccessModal';
import TextField from '../../shared/components/form/TextField';
import RadioButtonGroup from '../../shared/components/form/RadioButtonGroupHorizontal';

import { apiAction } from '../../redux/actions/apiActions';
import setResourceData from '../../helpers/setResourceData';
import { toCamelCase, humanizeCamelCase } from '../../helpers/convertKeys';
import randInt from '../../helpers/randInt';

const SalesOrdersForm = ({
  history: domHistory,
  carriers,
  shipmentProfiles,
  dispatch,
  countries,
}) => {
  const location = useLocation();
  const initialValues = location.state?.initialValues;
  const customerNotes = location.state?.customerNotes;
  const internalNotes = location.state?.internalNotes;
  const requestedShippingService = location.state?.requestedShippingService;
  const serviceCode = location.state?.serviceCode;

  const [serverErrors, setServerErrors] = useState({});
  const [createSuccess, setCreateSuccess] = useState(false);

  const resourceData = setResourceData(domHistory);
  const { resource, resourceExtension } = resourceData;

  const contentsList = shipmentProfiles ? shipmentProfiles.map((sp) => ({ label: sp.name, value: sp.id })) : [];
  const carriersList = carriers.filter((car) => car.id !== 'drop_off').map((carrier) => {
    const hasTransitTime = !!carrier.transitTime;
    const transitTime = hasTransitTime ? ` --- ${carrier.transitTime}` : '';
    return { label: carrier.name + transitTime, value: carrier.id };
  });
  const countryList = countries.map((c) => ({ label: c.name, value: c.code }));

  const handleClearServerErrors = () => setServerErrors({});
  return (
    <Container>
      <div className="container mb-5">
        <h3>Relevant Data</h3>
        <p className="ml-4"><b>Customer Notes: </b> {customerNotes || 'N/A'}</p>
        <p className="ml-4"><b>Internal Notes: </b> {internalNotes || 'N/A'}</p>
        <p className="ml-4"><b>Requested Service: </b> {requestedShippingService || 'N/A'}</p>
        <p className="ml-4"><b>Service Code: </b> {serviceCode || 'N/A'}</p>
      </div>
      <Formik
        initialValues={
          initialValues || {
            name: '',
            contactEmail: '',
            business: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            poNumber: '',
            isResidential: 'false',
            carrierServiceCode: '',
            fulfillmentType: 'new_order',
            lineItems: [{ contentId: '', quantity: '', id: randInt() }],
          }
        }
        validate={(values) => {
          const errors = {};

          if (!values.name) errors.name = 'Required';
          if (values.carrierServiceCode !== 'pick_up') {
            ['address1', 'city', 'zip', 'country'].forEach((key) => {
              if (!values[key]) errors[key] = 'Required';
            });
          }

          const allContentIds = values.lineItems.map((itm) => itm.contentId);
          const unfilteredDuplicates = allContentIds.filter((item, index) => allContentIds.indexOf(item) !== index);
          const duplicateContentIds = unfilteredDuplicates.filter((itm) => itm !== '');
          const lineItemErrors = [];
          values.lineItems.forEach((itm) => {
            const indexErrors = {};
            if (itm.quantity === '' || Number(itm.quantity) < 1) indexErrors.quantity = 'Minimum 1 Required';
            if (itm.contentId === '') indexErrors.contentId = 'At least one shippable item must be selected';
            if (duplicateContentIds.includes(itm.contentId)) indexErrors.contentId = 'Duplicate shipment contents';
            lineItemErrors.push(indexErrors);
          });

          const hasLineErrors = lineItemErrors.map((e) => (
            Object.entries(e).length !== 0 && e.constructor === Object
          )).includes(true);
          if (hasLineErrors) {
            errors.lineItems = lineItemErrors;
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setTimeout(() => {
            dispatch(
              apiAction(
                {
                  resource,
                  method: 'POST',
                  params: {
                    ...values,
                    isResidential: values.isResidential === 'true',
                    requestedShippingOptions: { carrierServiceCode: values.carrierServiceCode },
                  },
                  url: `/${resourceExtension}`,
                  onSuccess: () => {
                    setCreateSuccess(true);
                    setTimeout(() => { domHistory.goBack(); }, 1000);
                    return { type: 'FORM_REQUEST' };
                  },
                  onFailure: (errors) => {
                    const fieldErrors = {};
                    errors.forEach((e) => { fieldErrors[toCamelCase(e.field)] = e.message; });
                    setServerErrors(fieldErrors);
                    setErrors(fieldErrors);
                    return { type: 'FORM_REQUEST' };
                  },
                  domHistory,
                },
              ),
            );
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          isSubmitting, values, touched, errors, setFieldValue, setFieldTouched,
        }) => (
          <Form className="form">
            <SuccessModal
              isOpen={createSuccess}
              title="Order Successfully Created!"
              colored
              btn="Success"
              message={`You will be redirected back to the ${resourceExtension} index page.`}
            />
            <div className="col-md-12">
              <h3 className="page-title">
                New Sales Order Form
              </h3>
              <h3 className="page-subhead subhead">
                Use this form to create a new sales order.
              </h3>
              <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
                <div className="customizer__form-group-title-wrap mb-3">
                  <h4>Address and Carrier Information</h4>
                </div>
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col lg={4}>
                      <TextField
                        label="Contact Name"
                        fieldName="name"
                        placeholder="Contact Name..."
                      />
                    </Col>
                    <Col lg={4}>
                      <TextField
                        label="PO Number"
                        fieldName="poNumber"
                        placeholder="PO Number..."
                      />
                    </Col>
                    <Col lg={4}>
                      <SelectField
                        label="Carrier Service"
                        value={values.carrierServiceCode}
                        filterKey="carrierServiceCode"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.carrierServiceCode}
                        touched={touched.carrierServiceCode}
                        placeholder="Carrier Service..."
                        options={carriersList}
                        sorted={false}
                      />
                    </Col>
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <Col lg={4}>
                      <TextField
                        label="Contact Email"
                        fieldName="contactEmail"
                        placeholder="Contact Email..."
                      />
                    </Col>
                    <Col lg={4}>
                      <TextField
                        label="Contact Business"
                        fieldName="business"
                        placeholder="Contact Business..."
                      />
                    </Col>
                    <Col lg={4}>
                      <RadioButtonGroup
                        id="isResidential"
                        groupLabel="Residential Address?"
                        value={values.isResidential}
                        onBlur={setFieldTouched}
                        error={errors.isResidential}
                        touched={touched.isResidential}
                        options={[
                          { value: 'true', label: 'Yes' },
                          { value: 'false', label: 'No' },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <Col lg={6}>
                      <TextField
                        label="Address 1"
                        fieldName="address1"
                        placeholder="Address 1..."
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Address 2"
                        fieldName="address2"
                        placeholder="Address 2..."
                      />
                    </Col>
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <Col lg={3}>
                      <TextField
                        label="City"
                        fieldName="city"
                        placeholder="City..."
                      />
                    </Col>
                    <Col lg={3}>
                      <TextField
                        label="State/Province"
                        fieldName="state"
                        placeholder="State/Province..."
                      />
                    </Col>
                    <Col lg={3}>
                      <SelectField
                        label="Country"
                        value={values.country}
                        filterKey="country"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.country}
                        touched={touched.country}
                        placeholder="Country..."
                        options={countryList}
                        firstValue="US"
                      />
                    </Col>
                    <Col lg={3}>
                      <TextField
                        label="Postal Code"
                        fieldName="zip"
                        placeholder="Postal Code..."
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
                <div className="customizer__form-group-title-wrap">
                  <h4>Line Items</h4>
                </div>
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col>
                      <ContentsArrayField
                        shipmentProfiles={contentsList}
                        fieldName="carrierServiceCode"
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isSubmitting={isSubmitting}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
                <div className="ml-3 mr-5">
                  <Alert
                    color="danger"
                    className="alert--bordered"
                    icon
                    visible={Object.keys(serverErrors).length > 0}
                    onDismiss={handleClearServerErrors}
                  >
                    <p>
                      <span className="bold-text mr-2">
                        Warning!
                      </span>
                      One or more errors are preventing this record from being saved!
                    </p>
                    {
                      Object.keys(serverErrors).map((errorKey) => (
                        <p className="ml-2" key={`error-${errorKey}`}>
                          <b className="mr-1">{humanizeCamelCase(errorKey)}:</b>
                          { serverErrors[errorKey] }
                        </p>
                      ))
                    }
                  </Alert>
                  <Row>
                    <Col xs={6}>
                      <Button
                        block
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={domHistory.goBack}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button
                        block
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Create New Order
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

SalesOrdersForm.propTypes = {
  carriers: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.object),
  shipmentProfiles: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

SalesOrdersForm.defaultProps = {
  carriers: [],
  countries: [],
  shipmentProfiles: [],
};

const mapStateToProps = (state) => ({
  carriers: getFilterables(state).carrierServices,
  countries: getFilterables(state).countries,
  shipmentProfiles: getFilterables(state).shipmentProfiles,
});


export default withRouter(connect(mapStateToProps)(SalesOrdersForm));
