import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import { DropdownToggle, Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatType, formatNumber } from '../../helpers/resourceItem';
import randInt from '../../helpers/randInt';

const ItemsIndexContainer = () => {
  const [openMenuId, setOpenMenuId] = useState(null);

  const createRows = (list) => (
    list.map((item) => {
      const {
        id,
        aStock,
        aStockAssigned,
        bStock,
        bStockAssigned,
        baseContentId,
        defectiveStock,
        itemType,
        name,
      } = item;

      return (
        <tr key={randInt()}>
          <td>{name}</td>
          <td>{baseContentId}</td>
          <td>{formatType(itemType)}</td>
          <td>{formatNumber(aStock)}</td>
          <td>{formatNumber(aStockAssigned)}</td>
          <td>{itemType === 'expensed' ? '-' : formatNumber(bStock)}</td>
          <td>{itemType === 'expensed' ? '-' : formatNumber(bStockAssigned)}</td>
          <td>{itemType === 'expensed' ? '-' : formatNumber(defectiveStock)}</td>
          <td>
            <Dropdown size="sm" isOpen={openMenuId === id} toggle={() => setOpenMenuId(id)} className="nav-link">
              <DropdownToggle caret>
                Actions
              </DropdownToggle>
              <DropdownMenu container="body">
                <DropdownItem><Link to={`/inventory/items/${id}/edit`}>Edit</Link></DropdownItem>
                <DropdownItem><Link to={`/inventory/items/${id}`}>Information</Link></DropdownItem>
                <DropdownItem><Link to={`/inventory/items/${id}/print-label`}>Print Label</Link></DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </td>
        </tr>
      );
    })
  );

  const buildBadgeList = (filter, defaultFilter) => {
    const { itemType, search } = filter;
    const { defaultItemType, defaultSearch } = defaultFilter;
    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('itemType'):
          if (itemType && itemType !== defaultItemType) {
            badgeList.push(
              {
                order: 2,
                title: 'Item Type',
                desc: filter[key].replace('_', ' '),
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });
    return badgeList;
  };

  return (
    <IndexTableContainer
      newButtonText="New Inventory Item"
      newButton
      newFormUri="/inventory/items/new"
      pageTitle="Inventory Items"
      searchPlaceholder="Search by Item Name or Fulfillment SKU..."
      heads={
        [
          { key: 'name', name: 'Name' },
          { key: 'id', name: 'Fulfillment SKU' },
          { key: 'itemType', name: 'Item Type' },
          { key: 'a_stock', name: 'A-Stock' },
          { key: 'a_stock_assigned', name: 'A-Assigned' },
          { key: 'b_stock', name: 'B-Stock' },
          { key: 'b_stock_assigned', name: 'B-Assigned' },
          { key: 'defective_stock', name: 'Defective' },
          { key: 'information', name: 'Actions' },
        ]
      }
      filterForm={
        [
          {
            type: 'RadioButtonGroup',
            groupLabel: 'Item Type',
            filterKey: 'itemType',
            options: [
              { value: '', label: 'All' },
              { value: 'expensed', label: 'Expensed' },
              { value: 'serial_number', label: 'Serial Number' },
              { value: 'mailing_container', label: 'Mailing Container' },
            ],
          },
        ]
      }
      createRows={createRows}
      buildBadgeList={buildBadgeList}
    />
  );
};

export default ItemsIndexContainer;
