import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id }) => {
          if (!id) return null;
          const initialValues = { id };

          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/print_label`}
              formTitle="Print Item Label Confirmation"
              formSubTitle="Please confirm that you would like to print a label for this product."
              successModalTitle="Label Printed!"
              successModalMessage="You'll be sent back to the previous page"
              submitButtonText="Confirm Print"
              successUrl="/inventory/items"
            />
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
