/* eslint-disable react/prop-types */
import React from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import TextField from '../../shared/components/form/TextField';
import FormWrapper from '../../shared/components/form/FormWrapper';
import ImageField from '../../shared/components/form/ImageField';


const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const Form = ({ defaultValues, formSuffix }) => {
  const initialValues = defaultValues || {
    name: '',
    files: { avatar: {} },
  };

  return (
    <Container>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        formTitle={`${defaultValues ? 'Edit' : 'New'} Product Line Form`}
        successModalTitle={`Product Line successfully ${defaultValues ? 'updated' : 'created'}`}
        successModalMessage="You will be redirected back to the Product Lines index"
        submitButtonText={defaultValues ? 'Update' : 'Create'}
        successUrl="/inventory/product-lines"
        requestMethod={defaultValues && 'PUT'}
        formUrlSuffix={formSuffix || ''}
      >
        {
          ({ values, setFieldValue, errors }) => (
            <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
              <div className="mx-3">
                <Row style={{ width: '100%' }}>
                  <Col lg={12}>
                    <TextField
                      label="Name"
                      fieldName="name"
                      placeholder="Name..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <ImageField
                      label="Product Line Image"
                      files={values.files}
                      setFieldValue={setFieldValue}
                      formKey="avatar"
                      errors={errors}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )
        }
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Form);
