import React from 'react';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';

const MerchantsIndexContainer = () => {
  const createRows = (list) => (
    list.map((merchant) => {
      const {
        id,
        name,
        shortName,
        timeZone,
        avatar,
      } = merchant;

      return (
        <tr key={id}>
          <td>
            {
              avatar
                ? (
                  <img
                    src={avatar}
                    alt="asdf"
                    style={{
                      verticalAlign: 'middle',
                      width: 50,
                      height: 50,
                      borderRadius: 12,
                    }}
                  />
                )
                : (
                  <div
                    style={{
                      backgroundColor: '#ccc',
                      borderRadius: 12,
                      width: 50,
                      height: 50,
                      textAlign: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontSize: 25,
                        lineHeight: 1,
                        position: 'relative',
                        top: 12,
                      }}
                    >
                      {name.charAt(0).toUpperCase()}
                    </span>
                  </div>
                )
            }
          </td>
          <td>{name}</td>
          <td>{shortName}</td>
          <td>{timeZone}</td>
        </tr>
      );
    })
  );

  const buildBadgeList = (filter, defaultFilter) => {
    const { search, status } = filter;
    const { defaultSearch, defaultStatus } = defaultFilter;
    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            badgeList.push(
              {
                order: 1,
                title: 'Status',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });
    return badgeList;
  };

  return (
    <IndexTableContainer
      newButtonText="New Merchant"
      newButton
      newFormUri="/merchants/merchants/new"
      pageTitle="Merchants"
      searchPlaceholder="Search Name or Merchant ID..."
      heads={
        [
          { key: 'id', name: 'ID' },
          { key: 'name', name: 'Name' },
          { key: 'shortName', name: 'Short Name' },
          { key: 'timeZone', name: 'Time Zone' },
        ]
      }
      filterForm={
        [
          {
            type: 'RadioButtonGroup',
            groupLabel: 'Status',
            filterKey: 'status',
            options: [
              { value: '', label: 'All' },
              { value: 'active', label: 'Active' },
              { value: 'archived', label: 'Archived' },
            ],
          },
        ]
      }
      createRows={createRows}
      buildBadgeList={buildBadgeList}
    />
  );
};

export default MerchantsIndexContainer;
