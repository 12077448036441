/* eslint-disable no-console */
import CryptoJS from 'crypto-js';
// Note that for larger files, you may want to hash them incrementally.
// Taken from https://stackoverflow.com/questions/768268/
const md5FromFile = (file) => new Promise((resolve, reject) => {
  // FileReader is event driven, does not return promise
  // Wrap with promise api so we can call w/ async await
  // https://stackoverflow.com/questions/34495796
  const reader = new FileReader();

  reader.onload = (fileEvent) => {
    const binary = CryptoJS.lib.WordArray.create(fileEvent.target.result);
    const md5 = CryptoJS.MD5(binary);
    resolve(md5);
  };
  reader.onerror = () => {
    // eslint-disable-next-line prefer-promise-reject-errors
    reject('oops, something went wrong with the file reader.');
  };
  // For some reason, readAsBinaryString(file) does not work correctly,
  // so we will handle it as a word array
  reader.readAsArrayBuffer(file);
});

export const fileChecksum = async (file) => {
  const md5 = await md5FromFile(file);
  const checksum = md5.toString(CryptoJS.enc.Base64);
  return checksum;
};

const createPresignedUrl = async (file, checksum) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      file: {
        filename: file.name,
        byte_size: file.size,
        checksum,
        content_type: file.type,
        metadata: {},
      },
    }),
  };
  const res = await fetch(`${process.env.REACT_APP_BASE_URL}/presigned_url`, options);
  if (res.status !== 200) return res;
  return res.json();
};

export const getPresignedUrlAndUploadFileToS3 = async (file, fileKey) => {
  // To upload file file to S3, we need to do two steps:
  // 1) request a pre-signed PUT request (for S3) from the backend
  const checksum = await fileChecksum(file);
  const presignedFileParams = await createPresignedUrl(file, checksum);
  console.log({ presignedFileParams });
  if (!presignedFileParams.data) return { ok: false, field: fileKey, message: 'presigned url failure!' };

  // 2) send file to said PUT request (to S3)
  const s3PutOptions = {
    method: 'PUT',
    headers: presignedFileParams.data.direct_upload.headers,
    body: file,
  };
  const awsRes = await fetch(presignedFileParams.data.direct_upload.url, s3PutOptions);
  if (awsRes.status !== 200) return { ok: false, field: fileKey, message: 'upload to s3 failure!' };

  return { ok: true, data: presignedFileParams.data.blob_signed_id, fileKey };
};
