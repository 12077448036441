import React from 'react';
import { connect } from 'react-redux';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import { getApiPending } from '../../redux/reducers/apiReducer';
import { getAuthenticated } from '../../redux/reducers/authReducer';

import LoadingAnimation from '../../shared/components/general/LoadingAnimation';
import LogIn from '../LogIn/index';
import ForgotPassword from '../ForgotPassword/index';
import ResetPassword from '../ResetPassword/index';
import AcceptInvite from '../AcceptInvite/index';
import AssignmentsDeviceForm from '../Assignments/deviceFormIndex';
import AssignmentsKitForm from '../Assignments/kitFormIndex';
import AssignmentsKitUpdateForm from '../Assignments/kitUpdateFormIndex';
import AssignmentsForm from '../Assignments/formIndex';
import AssignmentsRemoveForm from '../Assignments/removeFormIndex';
import AssignmentsKitDeviceMissingForm from '../Assignments/kitDeviceMissingFormIndex';
import AssignmentsKitDeviceChangeForm from '../Assignments/kitDeviceChangeFormIndex';
import BundlesIndex from '../Bundles/index';
import BundlesFormIndex from '../Bundles/formIndex';
import BundlesShow from '../Bundles/showIndex';
import BatchesIndex from '../Batches/index';
import BatchesShow from '../Batches/showIndex';
import BatchesForm from '../Batches/formIndex';
import BatchesPrintLabel from '../Batches/printConfirmation';

import BatchesProgressForm from '../Batches/progressFormIndex';
import BatchesDevicesForm from '../Batches/devicesFormIndex';
import BatchesRemoveForm from '../Batches/removeDeviceFormIndex';
import BatchesCompletedform from '../Batches/completedFormIndex';

// As we add more sources, we'll wrap this with an ExternalSalesOrders index;
import ShipStationSalesOrders from '../ShipStationSalesOrders/index';
import WooCommerceSalesOrders from '../WooCommerceSalesOrders/index';

import OrderImportLogs from '../OrderImportLogs/index';

import SalesOrdersBatchIndex from '../SalesOrdersBatches/index';
import SalesOrdersBatchForm from '../SalesOrdersBatches/formIndex';
import SalesOrdersIndex from '../SalesOrders/index';
import SalesOrdersForm from '../SalesOrders/formIndex';
import SalesOrdersCancelForm from '../SalesOrders/cancelFormIndex';
import SalesOrdersShow from '../SalesOrders/showIndex';
import SalesShipmentsIndex from '../SalesShipments/index';
import SalesShipmentsShow from '../SalesShipments/showIndex';
import ShipmentsCancelForm from '../SalesShipments/cancelFormIndex';
import ShipmentsHoldForm from '../SalesShipments/holdFormIndex';
import ShipmentsAcknowledgeForm from '../SalesShipments/acknowledgeFormIndex';
import ShipmentsTrackingForm from '../SalesShipments/trackingFormIndex';
import ShipmentsSetWeightForm from '../SalesShipments/setWeightFormIndex';
import ShipmentsDropSetWeightForm from '../SalesShipments/dropSetWeightFormIndex';
import ShipmentsSetContainerForm from '../SalesShipments/setContainerFormIndex';
import ShipmentsDropContainerForm from '../SalesShipments/dropContainerFormIndex';
import ShipmentsCreateLabelForm from '../SalesShipments/createLabelFormIndex';
import ShipmentsCreateReturnLabelForm from '../SalesShipments/createReturnLabelFormIndex';
import ShipmentsPrintLabelForm from '../SalesShipments/printLabelFormIndex';
import ShipmentsPrintReturnLabelForm from '../SalesShipments/printReturnLabelFormIndex';
import ShipmentsDropTrackingForm from '../SalesShipments/dropTrackingFormIndex';
import ShipmentsProcessedForm from '../SalesShipments/processedFormIndex';
import ShipmentsServiceForm from '../SalesShipments/setCarrierService';

import DevicesIndex from '../Devices/index';
import DevicesEditForm from '../Devices/editFormIndex';
import ItemsIndex from '../Items/index';
import ItemsFormIndex from '../Items/formIndex';
import ItemsEditForm from '../Items/editFormIndex';
import ItemsPrintLabel from '../Items/printConfirmation';
import ItemsShow from '../Items/showIndex';
import InventoryLogsIndex from '../InventoryLogs/index';
import InventoryLogsShow from '../InventoryLogs/showIndex';
import KitsIndex from '../Kits/index';
import KitsShow from '../Kits/showIndex';
import KitsSunsetForm from '../Kits/sunsetFormIndex';
import KitsTestedForm from '../Kits/testedFormIndex';
import KitsPrintLabel from '../Kits/printConfirmation';
import KitsAddDeviceForm from '../Kits/addDeviceFormIndex';
import KitsReplaceDeviceForm from '../Kits/replaceDeviceFormIndex';
import ReturnOrdersIndex from '../ReturnOrders/index';
import ReturnOrdersForm from '../ReturnOrders/formIndex';
import ReturnOrdersExchangeForm from '../ReturnOrders/exchangeFormIndex';
import ReturnOrdersShow from '../ReturnOrders/showIndex';
import ReturnShipmentsIndex from '../ReturnShipments/index';
import ReturnShipmentsShow from '../ReturnShipments/showIndex';
import ReturnShipmentsProcessedForm from '../ReturnShipments/processedFormIndex';
import ReturnLineItemForm from '../ReturnLineItems/itemFormIndex';
import ReturnLineItemKitForm from '../ReturnLineItems/kitFormIndex';

import ReceivingOrdersIndex from '../ReceivingOrders/index';
import ReceivingOrdersShow from '../ReceivingOrders/showIndex';
import ReceivingOrdersForm from '../ReceivingOrders/formIndex';
import ReceivingOrdersProcessedForm from '../ReceivingOrders/processedFormIndex';
import ReceivingShipmentsIndex from '../ReceivingShipments/index';
import ReceivingShipmentsShow from '../ReceivingShipments/showIndex';
import ReceivingShipmentsForm from '../ReceivingShipments/formIndex';
import ReceivingShipmentsDevicesForm from '../ReceivingShipments/devicesFormIndex';
import ReceivingShipmentsArrivedForm from '../ReceivingShipments/arrivedFormIndex';
import ReceivingShipmentsProcessedForm from '../ReceivingShipments/processedFormIndex';
import NotFound from '../NotFound/index';

import MerchantsIndex from '../Merchants/index';
import MerchantsFormIndex from '../Merchants/formIndex';

import ProductLinesIndex from '../InventoryProductLines/index';
import ProductLinesFormIndex from '../InventoryProductLines/formIndex';

const Inventory = () => (
  <Switch>
    <Route exact path="/inventory/items" component={ItemsIndex} />
    <Route exact path="/inventory/items/new" component={ItemsFormIndex} />
    <Route exact path="/inventory/items/:id" component={ItemsShow} />
    <Route exact path="/inventory/items/:id/edit" component={ItemsEditForm} />
    <Route exact path="/inventory/items/:id/print-label" component={ItemsPrintLabel} />

    <Route exact path="/inventory/logs" component={InventoryLogsIndex} />
    <Route exact path="/inventory/logs/:id" component={InventoryLogsShow} />
    <Route exact path="/inventory/devices" component={DevicesIndex} />
    <Route exact path="/inventory/devices/:id/edit" component={DevicesEditForm} />
    <Route exact path="/inventory/bundles" component={BundlesIndex} />
    <Route exact path="/inventory/bundles/new" component={BundlesFormIndex} />
    <Route exact path="/inventory/bundles/:id" component={BundlesShow} />
    <Route exact path="/inventory/kits" component={KitsIndex} />
    <Route exact path="/inventory/kits/:id/sunset" component={KitsSunsetForm} />
    <Route exact path="/inventory/kits/:id/mark-as-tested" component={KitsTestedForm} />
    <Route exact path="/inventory/kits/:id/print-label" component={KitsPrintLabel} />
    <Route exact path="/inventory/kits/:id/add-device/:kdvId" component={KitsAddDeviceForm} />
    <Route exact path="/inventory/kits/:id/replace-device/:serialNumber" component={KitsReplaceDeviceForm} />
    <Route exact path="/inventory/kits/:id" component={KitsShow} />
    <Route exact path="/inventory/batches" component={BatchesIndex} />
    <Route exact path="/inventory/batches/new" component={BatchesForm} />
    <Route exact path="/inventory/batches/:id" component={BatchesShow} />
    <Route exact path="/inventory/batches/:id/mark-as-in-progress" component={BatchesProgressForm} />
    <Route exact path="/inventory/batches/:id/devices" component={BatchesDevicesForm} />
    <Route exact path="/inventory/batches/:id/print-labels" component={BatchesPrintLabel} />
    <Route exact path="/inventory/batches/:id/remove-device/:serialNumber" component={BatchesRemoveForm} />
    <Route exact path="/inventory/batches/:id/mark-as-completed" component={BatchesCompletedform} />
    <Route exact path="/inventory/product-lines" component={ProductLinesIndex} />
    <Route exact path="/inventory/product-lines/new" component={ProductLinesFormIndex} />
    <Route component={NotFound} />
  </Switch>
);

const ExternalOrders = () => (
  <Switch>
    <Route exact path="/external-orders/order-import-logs" component={OrderImportLogs} />
    <Route exact path="/external-orders/ship-station" component={ShipStationSalesOrders} />
    <Route exact path="/external-orders/woo-commerce" component={WooCommerceSalesOrders} />
  </Switch>
);

const Orders = () => (
  <Switch>
    <Route exact path="/orders/receiving-orders" component={ReceivingOrdersIndex} />
    <Route exact path="/orders/receiving-orders/new" component={ReceivingOrdersForm} />
    <Route exact path="/orders/receiving-orders/:id/receiving-shipments/new" component={ReceivingShipmentsForm} />
    <Route exact path="/orders/receiving-orders/:id/mark-as-processed" component={ReceivingOrdersProcessedForm} />
    <Route exact path="/orders/receiving-orders/:id" component={ReceivingOrdersShow} />
    <Route exact path="/orders/receiving-shipments" component={ReceivingShipmentsIndex} />
    <Route exact path="/orders/receiving-shipments/:id/devices" component={ReceivingShipmentsDevicesForm} />
    <Route exact path="/orders/receiving-shipments/:id/mark-as-arrived" component={ReceivingShipmentsArrivedForm} />
    <Route exact path="/orders/receiving-shipments/:id/mark-as-processed" component={ReceivingShipmentsProcessedForm} />
    <Route exact path="/orders/receiving-shipments/:id" component={ReceivingShipmentsShow} />
    <Route exact path="/orders/batch-sales-orders" component={SalesOrdersBatchIndex} />
    <Route exact path="/orders/batch-sales-orders/new" component={SalesOrdersBatchForm} />
    <Route exact path="/orders/sales-orders" component={SalesOrdersIndex} />
    <Route exact path="/orders/sales-orders/new" component={SalesOrdersForm} />
    <Route exact path="/orders/sales-orders/:id/new-exchange" component={ReturnOrdersExchangeForm} />
    <Route exact path="/orders/sales-orders/:id/cancel" component={SalesOrdersCancelForm} />
    <Route exact path="/orders/sales-orders/:id" component={SalesOrdersShow} />
    <Route exact path="/orders/sales-shipments" component={SalesShipmentsIndex} />
    <Route exact path="/orders/sales-shipments/:id/cancel" component={ShipmentsCancelForm} />
    <Route exact path="/orders/sales-shipments/:id/hold" component={ShipmentsHoldForm} />
    <Route exact path="/orders/sales-shipments/:id/add-tracking" component={ShipmentsTrackingForm} />
    <Route exact path="/orders/sales-shipments/:id/set-weight" component={ShipmentsSetWeightForm} />
    <Route exact path="/orders/sales-shipments/:id/drop-set-weight" component={ShipmentsDropSetWeightForm} />
    <Route exact path="/orders/sales-shipments/:id/set-mailing-container" component={ShipmentsSetContainerForm} />
    <Route exact path="/orders/sales-shipments/:id/drop-mailing-container" component={ShipmentsDropContainerForm} />
    <Route exact path="/orders/sales-shipments/:id/remove-tracking" component={ShipmentsDropTrackingForm} />
    <Route exact path="/orders/sales-shipments/:id/create-label" component={ShipmentsCreateLabelForm} />
    <Route exact path="/orders/sales-shipments/:id/create-return-label" component={ShipmentsCreateReturnLabelForm} />
    <Route exact path="/orders/sales-shipments/:id/print-label" component={ShipmentsPrintLabelForm} />
    <Route exact path="/orders/sales-shipments/:id/print-return-label" component={ShipmentsPrintReturnLabelForm} />
    <Route exact path="/orders/sales-shipments/:id/mark-as-processed" component={ShipmentsProcessedForm} />
    <Route exact path="/orders/sales-shipments/:id/acknowledge-updates" component={ShipmentsAcknowledgeForm} />
    <Route exact path="/orders/sales-shipments/:id/set-carrier-service" component={ShipmentsServiceForm} />
    <Route exact path="/orders/sales-shipments/:id" component={SalesShipmentsShow} />
    <Route exact path="/orders/assignments/:id/kit-device-missing/:kdvId" component={AssignmentsKitDeviceMissingForm} />
    <Route exact path="/orders/assignments/:id/kit-device-change/:kdvId" component={AssignmentsKitDeviceChangeForm} />
    <Route exact path="/orders/assignments/:id/assign-device" component={AssignmentsDeviceForm} />
    <Route exact path="/orders/assignments/:id/assign-kit" component={AssignmentsKitForm} />
    <Route exact path="/orders/assignments/:id/update-kit/:kitId" component={AssignmentsKitUpdateForm} />
    <Route exact path="/orders/assignments/:id/assign" component={AssignmentsForm} />
    <Route exact path="/orders/assignments/:id/remove" component={AssignmentsRemoveForm} />
    <Route exact path="/orders/return-orders/new" component={ReturnOrdersForm} />
    <Route exact path="/orders/return-orders/:id" component={ReturnOrdersShow} />
    <Route exact path="/orders/return-orders" component={ReturnOrdersIndex} />
    <Route exact path="/orders/return-shipments" component={ReturnShipmentsIndex} />
    <Route exact path="/orders/return-shipments/:id/cancel" component={ShipmentsCancelForm} />
    <Route exact path="/orders/return-shipments/:id/add-tracking" component={ShipmentsTrackingForm} />
    <Route exact path="/orders/return-shipments/:id/remove-tracking" component={ShipmentsDropTrackingForm} />
    <Route exact path="/orders/return-shipments/:id" component={ReturnShipmentsShow} />
    <Route exact path="/orders/return-shipments/:id/mark-as-processed" component={ReturnShipmentsProcessedForm} />
    <Route exact path="/orders/return-line-items/:id/update-kit/:key" component={ReturnLineItemKitForm} />
    <Route exact path="/orders/return-line-items/:id/update" component={ReturnLineItemForm} />
    <Route component={NotFound} />
  </Switch>
);

const mapStateToProps = (state) => ({
  pending: getApiPending(state),
  authenticated: getAuthenticated(state),
});

const wrappedRoutes = connect(mapStateToProps)(({ pending, authenticated, ...props }) => (
  authenticated
    ? (
      <div className={`${pending ? 'loading-wrapper' : ''}`}>
        { pending && <LoadingAnimation /> }
        <Layout />
        <div className="container__wrap">
          <Switch>
            <Route exact path="/merchants/merchants" component={MerchantsIndex} />
            <Route exact path="/merchants/merchants/new" component={MerchantsFormIndex} />
            <Route path="/inventory" component={Inventory} />
            <Route path="/orders" component={Orders} />
            <Route path="/external-orders" component={ExternalOrders} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    )
    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
));

const AppRouter = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/"><Redirect to="/inventory/items" /></Route>
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:resetPasswordToken" component={ResetPassword} />
        <Route path="/accept-invite/:inviteToken" component={AcceptInvite} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default withRouter(connect()(AppRouter));
