import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { apiAction } from '../../../redux/actions/apiActions';
import setResourceData from '../../../helpers/setResourceData';

class ShowContainer extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    const {
      dispatch, history: domHistory, match: { params }, fetchUrl,
    } = this.props;
    const resourceData = setResourceData(domHistory, params);
    const { id, resourceExtension } = resourceData;
    dispatch(
      apiAction(
        {
          method: 'GET',
          url: (fetchUrl || `/${resourceExtension}/${id}`),
          onSuccess: (res) => {
            this.setState({ data: res });
            return { type: 'FORM_REQUEST' };
          },
          onFailure: () => ({ type: 'FORM_REQUEST' }),
          domHistory,
        },
      ),
    );
  }

  render() {
    const { children } = this.props;
    const { data } = this.state;
    return <span>{children(data)}</span>;
  }
}

ShowContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line
  match: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
  // eslint-disable-next-line
  children: PropTypes.func,
  fetchUrl: PropTypes.string,
};

ShowContainer.defaultProps = {
  fetchUrl: null,
};

export default withRouter(connect()(ShowContainer));
