/* eslint-disable */ 
import React from 'react';
import { withRouter } from 'react-router-dom';
import FetchDataHoc from '../../shared/components/FetchDataHoc';
import Form from './formIndex';

// eslint-disable-next-line react/prop-types
const ExchangeForm = ({ match: { params } }) => {
  return (
    <FetchDataHoc fetchDataUrl={`/orders/${params.id}`}>
      {
        (fetchedData) => <Form defaultValues={{...fetchedData, lineItems: [], exchangeSalesOrderId: params.id, fulfillmentType: "return_order"}} hideReturnTracking />
      }
    </FetchDataHoc>
  );
};

export default withRouter(ExchangeForm);
