import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatDescriptionArrayToString, formatKitStockType } from '../../helpers/resourceItem';
import '../../helpers/dymo';

const DisabledLink = ({ id }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <span>
      <span className="link-text" id={`Tooltip-${id}`}>Print Label</span>
      <Tooltip
        placement="right"
        target={`Tooltip-${id}`}
        isOpen={tooltipOpen}
        toggle={toggle}
      >
        Printer Not Found
      </Tooltip>
    </span>
  );
};

DisabledLink.propTypes = {
  id: PropTypes.number.isRequired,
};

class KitsIndexContainer extends Component {
  createRows = (list) => (
    list.map((item) => {
      const {
        buildOrderId,
        id,
        ssid,
        status,
        type,
      } = item;

      let displaySunset;
      if (['untested_stock', 'a_stock'].includes(status)) {
        displaySunset = (
          <Link to={`/inventory/kits/${id}/sunset`}>Sunset</Link>
        );
      }

      let displayMarkAsTested;
      if (status === 'untested_stock') {
        displayMarkAsTested = (
          <Link to={`/inventory/kits/${id}/mark-as-tested`}>Mark as Tested</Link>
        );
      }

      return (
        <tr key={id}>
          <td>{type}</td>
          <td>{id}</td>
          <td>{ssid}</td>
          <td>{ formatKitStockType(status)}</td>
          <td>{buildOrderId}</td>
          <td>
            <Link to={`/inventory/kits/${id}`}>Info</Link>
            { displaySunset && <span className="mx-2">|</span> }
            { displaySunset }
            { displayMarkAsTested && <span className="mx-2">|</span> }
            { displayMarkAsTested }
            <span className="mx-2">|</span>
            <Link to={`/inventory/kits/${id}/print-label`}>Print Label</Link>
          </td>
        </tr>
      );
    })
  )


  buildBadgeList = (filter, defaultFilter) => {
    const {
      buildOrderId, buildListId, search, status,
    } = filter;
    const {
      buildOrderId: defaultBatchId,
      buildListId: defaultbuildListId,
      search: defaultSearch,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case 'buildOrderId':
          if (buildOrderId && buildOrderId !== defaultBatchId) {
            badgeList.push(
              {
                order: 3,
                title: 'Batch Id',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('buildListId'):
          if (buildListId && buildListId !== defaultbuildListId) {
            badgeList.push(
              {
                order: 4,
                title: 'Kit Type',
                descFilterable: 'bundles',
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            const baseDesc = filter[key];
            const builtDesc = [];
            if (baseDesc.includes('a_stock') && baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(with Assigned)');
            } else if (baseDesc.includes('a_stock') && !baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(w/o Assigned)');
            } else if (!baseDesc.includes('a_stock') && baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(only Assigned)');
            }

            if (baseDesc.includes('untested_stock')) {
              builtDesc.push('Untested');
            }

            if (baseDesc.includes('field_stock')) {
              builtDesc.push('Field');
            }

            if (baseDesc.includes('sunset_stock')) {
              builtDesc.push('Sunset');
            }

            const desc = formatDescriptionArrayToString(builtDesc);
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc,
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        pageTitle="Inventory Kits"
        searchPlaceholder="Search by Kit ID or SSID..."
        heads={
          [
            { key: 'type', name: 'Type' },
            { key: 'id', name: 'ID' },
            { key: 'ssid', name: 'SSID' },
            { key: 'status', name: 'Status' },
            { key: 'buildOrderId', name: 'Batch ID' },
            { key: 'actions', name: 'Actions' },
          ]
        }
        filterForm={
          [
            {
              type: 'CheckBoxGroup',
              groupLabel: 'Stock Types',
              filterKey: 'status',
              options: [
                { value: 'a_stock', label: 'A-Stock' },
                { value: 'a_stock_assigned', label: 'A-Stock Assigned' },
                { value: 'untested_stock', label: 'Untested Stock' },
                { value: 'field_stock', label: 'Field Stock' },
                { value: 'sunset_stock', label: 'Sunset Stock' },
              ],
            },
            {
              type: 'Text',
              groupLabel: 'Batch ID',
              filterKey: 'buildOrderId',
              placeholder: 'Search by Batch ID...',
            },
            {
              type: 'Select',
              groupLabel: 'Bundle Type',
              filterKey: 'buildListId',
              placeholder: 'Filter by Bundle...',
              optionsKey: 'prebuiltBundles',
            },
          ]
        }
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default KitsIndexContainer;
