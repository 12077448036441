import React, { PureComponent } from 'react';
import { Badge } from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';
import IndexTableContext from './IndexTableContext';
import { sortNumericallyBy } from '../../../helpers/resourceItem';

export default class FilterBadgesDisplay extends PureComponent {
  findFilterableName = (obj, filterables, filter) => {
    const { descFilterable, filterKey } = obj;
    let string;
    if (Array.isArray(filter[filterKey])) {
      const items = filterables[descFilterable].filter((i) => filter[filterKey].includes(i.id.toString()));
      const skus = items.map((i) => i.id);
      string = skus.length ? skus.join(', ').replace(/,(?=[^,]*$)/, ' & ') : skus[0];
    } else {
      const item = filterables[descFilterable].find((i) => (i.id).toString() === filter[filterKey].toString());
      string = item.name;
    }
    return string;
  };

  buildBadge = (obj, filterables, filter, onResetFilterKey) => {
    const { title, desc, filterKey } = obj;
    const hasDescription = !!desc;
    const description = hasDescription ? desc : this.findFilterableName(obj, filterables, filter);
    return (
      <Badge
        className="m-1"
        color="success filter-badge"
        key={title}
      >
        {`${title}:`}
        <span style={{ paddingLeft: '10px' }}>
          { description }
        </span>
        <CloseIcon
          size={15}
          className="close__icon"
          onClick={() => onResetFilterKey(filterKey)}
        />
      </Badge>
    );
  }

  render() {
    return (
      <IndexTableContext.Consumer>
        {
          ({
            buildBadgeList,
            defaultFilter,
            filter,
            filterables,
            onResetFilterKey,
          }) => (
            <div>
              {
                sortNumericallyBy(buildBadgeList(filter, defaultFilter), 'order').map((obj) => (
                  this.buildBadge(obj, filterables, filter, onResetFilterKey)
                ))
              }
            </div>
          )
        }
      </IndexTableContext.Consumer>
    );
  }
}
