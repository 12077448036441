export default function setResourceData(history, params = null) {
  const { location } = history;
  const { pathname } = location;
  const resourcePath = pathname.split('/')[2];
  const preResourcePath = pathname.split('/')[1];
  const id = params && params.id;
  let resourceData = {};
  switch (resourcePath) {
    case 'items':
      resourceData = {
        resource: 'items',
        resourceExtension: 'items',
      };
      break;
    case 'logs':
      resourceData = {
        resource: 'inventoryLogs',
        resourceExtension: 'inventory_logs',
      };
      break;
    case 'devices':
      resourceData = {
        resource: 'devices',
        resourceExtension: 'devices',
      };
      break;
    case 'bundles':
      resourceData = {
        resource: 'bundles',
        resourceExtension: 'build_lists',
      };
      break;
    case 'batches':
      resourceData = {
        resource: 'buildOrders',
        resourceExtension: 'build_orders',
      };
      break;
    case 'kits':
      resourceData = {
        resource: 'kits',
        resourceExtension: 'kits',
      };
      break;
    case 'receiving-orders':
      resourceData = {
        resource: 'receivingOrders',
        resourceExtension: 'vendor_orders',
      };
      break;
    case 'receiving-shipments':
      resourceData = {
        resource: 'receivingShipments',
        resourceExtension: 'vendor_shipments',
      };
      break;
    case 'batch-sales-orders':
      resourceData = {
        resource: 'salesOrderBatches',
        resourceExtension: 'order_groups',
      };
      break;
    case 'sales-orders':
      resourceData = {
        resource: 'salesOrders',
        resourceExtension: 'orders',
      };
      break;
    case 'sales-shipments':
      resourceData = {
        resource: 'salesShipments',
        resourceExtension: 'shipments',
      };
      break;
    case 'assignments':
      resourceData = {
        resource: 'assignments',
        resourceExtension: 'assignments',
      };
      break;
    case 'merchants':
      resourceData = {
        resource: 'merchants',
        resourceExtension: 'merchants',
      };
      break;
    case 'product-lines':
      resourceData = {
        resource: 'productLines',
        resourceExtension: 'product_lines',
      };
      break;
    case 'return-line-items':
      resourceData = {
        resource: 'returnLineItems',
        resourceExtension: 'shipment_requests',
      };
      break;
    case 'return-orders':
      resourceData = {
        resource: 'returnOrders',
        resourceExtension: 'orders',
      };
      break;
    case 'return-shipments':
      resourceData = {
        resource: 'returnShipments',
        resourceExtension: 'shipments',
      };
      break;
    case 'ship-station':
      resourceData = {
        resource: 'shipStationOrders',
        resourceExtension: 'ship_station_orders',
      };
      break;
    case 'woo-commerce':
      resourceData = {
        resource: 'wooCommerceOrders',
        resourceExtension: 'woo_commerce_orders',
      };
      break;
    case 'order-import-logs':
      resourceData = {
        resource: 'orderImportLogs',
        resourceExtension: 'order_import_logs',
      };
      break;
    // no default
  }
  return { ...resourceData, preResourcePath, id };
}
