import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatOrderStatus } from '../../helpers/resourceItem';
import { isFullyAssigned } from '../../helpers/shipments';
import ExpensedVariantExpanded from './components/index/ExpensedVariantExpanded';
import GenericBundleExpanded from './components/index/GenericBundleExpanded';
import SnVariantExpanded from './components/index/SnVariantExpanded';
import IdentifiableBundleExpanded from './components/index/IdentifiableBundleExpanded';

class ReturnShipmentIndex extends Component {
  formatContents = (shipment) => (
    shipment.shipmentRequests.map((sr) => {
      const {
        quantity,
        name,
        id,
        assignments,
        returnedData,
        requestType,
      } = sr;

      let display;
      switch (requestType) {
        case 'expensed_variant':
          display = (
            <ExpensedVariantExpanded
              id={id}
              data={returnedData}
              status={shipment.status}
            />
          );
          break;
        case 'generic_build_list':
          display = (
            <GenericBundleExpanded
              id={id}
              data={returnedData}
              status={shipment.status}
            />
          );
          break;
        case 'serial_number_variant':
          display = (
            <SnVariantExpanded
              status={shipment.status}
              assignments={assignments}
              trackingNumber={shipment.trackingNumber}
            />
          );
          break;
        case 'identifiable_build_list':
          display = (
            <IdentifiableBundleExpanded
              status={shipment.status}
              assignments={assignments}
              trackingNumber={shipment.trackingNumber}
            />
          );
          break;
        default:
          // no default
      }

      return (
        <div key={`shipmentRequests${id}`} className="ml-4">
          <div>
            {`Qty ${quantity}`}
            <span className="ml-2">{name}</span>
          </div>
          { display }
        </div>
      );
    })
  )

  createRows = (list) => (
    list.map((salesShipment) => {
      const {
        id,
        name,
        status,
        trackingNumber,
      } = salesShipment;

      let displayCancel;
      if (status === 'unprocessed') {
        displayCancel = (
          <Link to={`/orders/return-shipments/${id}/cancel`}>
            Cancel
          </Link>
        );
      }

      // let displayProcessed;
      let displayTracking;
      if (status === 'unprocessed' && !trackingNumber) {
        displayTracking = (
          <Link to={`/orders/return-shipments/${id}/add-tracking`}>
            Add
          </Link>
        );
      }
      if (trackingNumber) {
        displayTracking = (
          <span>
            {trackingNumber}
            {
              status === 'unprocessed'
              && (
                <Link className="ml-1" to={`/orders/return-shipments/${id}/remove-tracking`}>
                  <b>x</b>
                </Link>
              )
            }
          </span>
        );
      }

      let displayProcessed;
      if (status === 'unprocessed' && isFullyAssigned(salesShipment) && trackingNumber) {
        displayProcessed = (
          <Link to={`/orders/return-shipments/${id}/mark-as-processed`}>
            Mark As Processed
          </Link>
        );
      }

      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{formatOrderStatus(status)}</td>
          <td>{name}</td>
          <td>{displayTracking}</td>
          <td>{this.formatContents(salesShipment)}</td>
          <td>
            <Link to={`/orders/return-shipments/${id}`}>Show</Link>
            { displayCancel && <span className="mx-2">|</span> }
            { displayCancel }
            { displayProcessed && <span className="mx-2">|</span> }
            { displayProcessed }
          </td>
        </tr>
      );
    })
  )

  buildBadgeList = (filter, defaultFilter) => {
    const {
      contentId,
      processedDate,
      processedAfterDate,
      processedBeforeDate,
      search,
      status,
    } = filter;
    const {
      contentId: defaultContentId,
      processedDate: defaultProcessedDate,
      processedAfterDate: defaultProcessedAfterDate,
      processedBeforeDate: defaultProcessedBeforeDate,
      search: defaultSearch,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('contentId'):
          if (contentId && contentId !== defaultContentId) {
            badgeList.push(
              {
                order: 3,
                title: 'Contents',
                descFilterable: 'shipmentProfiles',
                filterKey: key,
              },
            );
          }
          break;
        case ('processedDate'):
          if (processedDate && processedDate !== defaultProcessedDate) {
            badgeList.push(
              {
                order: 4,
                title: 'Fulfillment Date',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('processedAfterDate'):
          if (processedAfterDate && processedAfterDate !== defaultProcessedAfterDate) {
            if (!processedBeforeDate) {
              badgeList.push(
                {
                  order: 4,
                  title: 'Fulfilled After',
                  desc: filter.processedAfterDate,
                  filterKey: key,
                },
              );
            }
          }
          break;
        case ('processedBeforeDate'):
          if (processedBeforeDate && processedBeforeDate !== defaultProcessedBeforeDate) {
            if (processedAfterDate && processedAfterDate !== defaultProcessedAfterDate) {
              badgeList.push(
                {
                  order: 4,
                  title: 'Fulfilled Between',
                  desc: `${filter.processedAfterDate} and ${filter.processedBeforeDate}`,
                  filterKey: ['processedBeforeDate', 'processedAfterDate'],
                },
              );
            } else {
              badgeList.push(
                {
                  order: 4,
                  title: 'Fulfilled Before',
                  desc: filter[key],
                  filterKey: 'processedBeforeDate',
                },
              );
            }
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc: filter[key].replace(/_/g, ' '),
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        newButtonText="New Return Order"
        pageTitle="Return Shipments"
        exportButton
        searchPlaceholder="Search by ID or Address..."
        heads={
          [
            { key: 'id', name: 'ID' },
            { key: 'status', name: 'Status' },
            { key: 'name', name: 'Name' },
            { key: 'tracking', name: 'Tracking' },
            { key: 'contents', name: 'Contents' },
            { key: 'actions', name: 'Actions' },
          ]
        }
        filterForm={
          [
            {
              type: 'RadioButtonGroup',
              groupLabel: 'Shipment Status',
              filterKey: 'status',
              options: [
                { value: '', label: 'All' },
                { value: 'unprocessed', label: 'Not Processed' },
                { value: 'processed_incomplete', label: 'Processed Incomplete' },
                { value: 'processed', label: 'Processed' },
                { value: 'canceled', label: 'Canceled' },
              ],
            },
            {
              type: 'Select',
              groupLabel: 'Contents',
              filterKey: 'contentId',
              placeholder: 'Filter by Contents...',
              optionsKey: 'shipmentProfiles',
            },
            {
              type: 'DateTime',
              groupLabel: 'Processed Date',
              filterKeys: {
                on: 'processedDate',
                before: 'processedBeforeDate',
                after: 'processedAfterDate',
              },
              filterTypes: ['on', 'before', 'after', 'range'],
            },
          ]
        }
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default ReturnShipmentIndex;
