import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckBoxIcon from 'mdi-react/CheckBoxIcon';
import CheckboxBlankOutlineIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import { switchPrintStation } from '../../../redux/actions/authActions';

const PrinterOption = ({
  dispatch, title, value, printer,
}) => {
  const icon = value === printer
    ? <CheckBoxIcon className="topbar__icon" />
    : <CheckboxBlankOutlineIcon className="topbar__icon" />;
  return (
    <button
      className="topbar__link"
      type="button"
      onClick={() => dispatch(switchPrintStation(value))}
    >
      { icon }
      <p className="topbar__link-title">{title}</p>
    </button>
  );
};

PrinterOption.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  printer: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  printer: state.auth.printer,
});

export default connect(mapStateToProps)(PrinterOption);
