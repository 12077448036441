import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { getResources } from '../../../redux/reducers/authReducer';
import PencilIcon from '../../../shared/components/icons/pencil';
import SerialNumberIcon from '../../../shared/components/icons/serialNumber';
import TagIcon from '../../../shared/components/icons/tag';
import ClipBoardIcon from '../../../shared/components/icons/clipboard';
import KitsIcon from '../../../shared/components/icons/kits';
import BatchesIcon from '../../../shared/components/icons/batches';
import ReceivingIcon from '../../../shared/components/icons/receiving';
import OrderIcon from '../../../shared/components/icons/orders';
import ReturnIcon from '../../../shared/components/icons/returns';
import CartIcon from '../../../shared/components/icons/cart';

const SidebarContent = ({ onClick, resources }) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink
        title="Inventory Items"
        icon={<TagIcon />}
        route="/inventory/items"
        onClick={onClick}
        display={resources.includes('items')}
      />
      <SidebarLink
        title="Devices"
        icon={<SerialNumberIcon />}
        route="/inventory/devices"
        onClick={onClick}
        display={resources.includes('devices')}
      />
      <SidebarLink
        title="Inventory Logs"
        icon={<PencilIcon />}
        route="/inventory/logs"
        onClick={onClick}
        display={resources.includes('logs')}
      />
      <SidebarLink
        title="Bundles"
        icon={<ClipBoardIcon />}
        route="/inventory/bundles"
        onClick={onClick}
        display={resources.includes('bundles')}
      />
      <SidebarLink
        title="Built Kits"
        icon={<KitsIcon />}
        route="/inventory/kits"
        onClick={onClick}
        display={resources.includes('kits')}
      />
      <SidebarLink
        title="Batches"
        icon={<BatchesIcon />}
        route="/inventory/batches"
        onClick={onClick}
        display={resources.includes('batches')}
      />
      <SidebarCategory
        display={resources.includes('receiving')}
        title="Receiving Orders"
        icon={<ReceivingIcon />}
      >
        <SidebarLink
          title="Orders"
          route="/orders/receiving-orders"
          onClick={onClick}
          display={resources.includes('receiving_orders')}
        />
        <SidebarLink
          title="Shipments"
          route="/orders/receiving-shipments"
          onClick={onClick}
          display={resources.includes('receiving_shipments')}
        />
      </SidebarCategory>
      <SidebarCategory
        title="External Sales Orders"
        icon={<CartIcon />}
        display={resources.includes('external')}
      >
        <SidebarLink
          title="Import Logs"
          route="/external-orders/order-import-logs"
          onClick={onClick}
          display={resources.includes('external_order_import_logs')}
        />
        <SidebarLink
          title="ShipStation"
          route="/external-orders/ship-station"
          onClick={onClick}
          display={resources.includes('external_ship_station')}
        />
        <SidebarLink
          title="WooCommerce"
          route="/external-orders/woo-commerce"
          onClick={onClick}
          display={resources.includes('external_woo_commerce')}
        />
      </SidebarCategory>
      <SidebarCategory
        title="Sales Orders"
        icon={<OrderIcon />}
        display={resources.includes('sales')}
      >
        <SidebarLink
          title="Batch Orders"
          route="/orders/batch-sales-orders"
          onClick={onClick}
          display={resources.includes('sales_order_batches')}
        />
        <SidebarLink
          title="Orders"
          route="/orders/sales-orders"
          onClick={onClick}
          display={resources.includes('sales_orders')}
        />
        <SidebarLink
          title="Shipments"
          route="/orders/sales-shipments"
          onClick={onClick}
          display={resources.includes('sales_shipments')}
        />
      </SidebarCategory>
      <SidebarCategory
        title="Sales Returns"
        icon={<ReturnIcon />}
        display={resources.includes('returns')}
      >
        <SidebarLink
          title="Orders Index"
          route="/orders/return-orders"
          onClick={onClick}
          display={resources.includes('return_orders')}
        />
        <SidebarLink
          title="Shipments Index"
          route="/orders/return-shipments"
          onClick={onClick}
          display={resources.includes('return_shipments')}
        />
      </SidebarCategory>
    </ul>
  </div>
);

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  resources: getResources(state),
});

export default connect(mapStateToProps)(SidebarContent);
