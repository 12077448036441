/* eslint-disable react/prop-types */
import React from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import TextField from '../../shared/components/form/TextField';
import FormWrapper from '../../shared/components/form/FormWrapper';
import SelectField from '../../shared/components/form/SelectFfield';
import TimeZones from './time_zone_helper';
import ImageField from '../../shared/components/form/ImageField';


const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  shortName: Yup.string().required('Required'),
  timeZone: Yup.string().required('Required'),
});

const Form = ({ defaultValues, formSuffix }) => {
  const initialValues = defaultValues || {
    name: '',
    shortName: '',
    timeZone: 'America/Chicago',
    files: { avatar: {} },
  };

  return (
    <Container>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        formTitle={`${defaultValues ? 'Edit' : 'New'} Merchant Form`}
        successModalTitle={`Merchant successfully ${defaultValues ? 'updated' : 'created'}`}
        successModalMessage="You will be redirected back to the Merchants index"
        submitButtonText={defaultValues ? 'Update' : 'Create'}
        successUrl="/merchants/merchants"
        requestMethod={defaultValues && 'PUT'}
        formUrlSuffix={formSuffix || ''}
      >
        {
          ({ values, setFieldValue, errors }) => (
            <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
              <div className="mx-3">
                <Row style={{ width: '100%' }}>
                  <Col lg={4}>
                    <TextField
                      label="Name"
                      fieldName="name"
                      placeholder="Name..."
                    />
                  </Col>
                  <Col lg={4}>
                    <TextField
                      label="Short Name"
                      fieldName="shortName"
                      placeholder="Short Name..."
                    />
                  </Col>
                  <Col lg={4}>
                    <SelectField
                      // isDisabled={!!defaultValues}
                      label="Time Zone"
                      placeholder="Time Zone..."
                      fieldName="timeZone"
                      options={TimeZones}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <ImageField
                      label="Merchant Avatar"
                      files={values.files}
                      setFieldValue={setFieldValue}
                      formKey="avatar"
                      errors={errors}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )
        }
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Form);
