import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { carrierToTitleCase } from './utils';


const CarrierService = ({
  // eslint-disable-next-line react/prop-types
  carrierServiceCode, id,
}) => {
  const carrierName = carrierServiceCode ? carrierServiceCode.split('_')[0].toUpperCase() : '';
  const readableServiceCode = carrierServiceCode ? carrierToTitleCase(carrierServiceCode) : '';

  return (
    <div>
      <b className="mr-2">Carrier Service</b>
      {`${carrierName} ${readableServiceCode}`}
      <Link
        style={{ color: '#70bbfd', cursor: 'pointer' }}
        className="ml-3"
        to={`sales-shipments/${id}/set-carrier-service`}
      >
        {carrierServiceCode ? 'Update Carrier Service' : 'Set Carrier Service'}
      </Link>
    </div>
  );
};

CarrierService.propTypes = {
  id: PropTypes.number.isRequired,
  carrierServiceCode: PropTypes.string,
  orderSource: PropTypes.shape({
    internalNotes: PropTypes.string,
    customerNotes: PropTypes.string,
    requestedShippingService: PropTypes.string,
    serviceCode: PropTypes.string,
  }),
};

CarrierService.defaultProps = {
  carrierServiceCode: null,
  orderSource: null,
};

export default withRouter(CarrierService);
