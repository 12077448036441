import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHENTICATION_ERROR,
  AUTHENTICATION_SUCCESS_MESSAGE,
  TOKEN_AUTHENTICATED,
  CHANGE_PRINTER,
} from '../actions/authActions';

const printer = localStorage.getItem('printer');

const initialState = {
  accounts: [],
  printer: printer || 'one',
  authenticated: false,
  carriers: [],
  currentAccountId: null,
  emailAddress: '',
  errors: [],
  filterables: {},
  firstName: '',
  invite: {},
  lastName: '',
  successMessage: '',
  resources: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case TOKEN_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        errors: [],
        firstName: action.firstName,
        lastName: action.lastName,
        emailAddress: action.emailAddress,
        accounts: action.accounts,
        currentAccountId: action.currentAccountId,
        carriers: action.carriers,
        filterables: action.filterables,
        resources: action.dashboardResources,
        wooCommerceConfig: action.wooCommerceConfig,
      };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false };
    case AUTHENTICATION_ERROR:
      return { ...state, errors: action.errors, successMessage: '' };
    case AUTHENTICATION_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.successMessage, errors: [] };
    case CHANGE_PRINTER:
      return { ...state, printer: action.printer };
    // no default
  }
  return state;
}

export const getAuthenticated = (state) => state.auth.authenticated;
export const getErrors = (state) => state.auth.errors;
export const getSuccessMessage = (state) => state.auth.successMessage;
export const getFirstName = (state) => state.auth.firstName;
export const getLastName = (state) => state.auth.lastName;
export const getEmailAddress = (state) => state.auth.emailAddress;
export const getCurrentAccountId = (state) => state.auth.currentAccountId;
export const getAccounts = (state) => state.auth.accounts;
export const getReturnedInvite = (state) => state.auth.invite;
export const getCarriers = (state) => state.auth.carriers;
export const getFilterables = (state) => state.auth.filterables;
export const getResources = (state) => state.auth.resources;
export const getWooCommerceConfig = (state) => state.auth.wooCommerceConfig;
