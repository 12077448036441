/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Badge } from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CloseCircle from 'mdi-react/CloseCircleOutlineIcon';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { isFullyAssigned, formatStatus } from '../../helpers/shipments';
import ShippingColumn from './components/index/ShippingColumn';
import DisabledLink from './components/index/DisabledLink';
import AddressValidationTooltip from './components/index/AddressValidationTooltip';
import { updateFilterAndSearch as updateApiFilterAndSearch } from '../../redux/actions/indexActions';
import randInt from '../../helpers/randInt';
import PackingSlip from './components/index/ShippingColumn/PackingSlip';

class SalesShipmentsIndexContainer extends Component {
  formatContents = (shipment) => (
    shipment.shipmentRequests.map((sr) => {
      const {
        quantity,
        name,
        id,
        assignments,
      } = sr;
      const { filter, updateFilterAndSearch, history } = this.props;
      return (
        <div key={`shipmentRequests${id}`} className="ml-4">
          <div>
            {`Qty ${quantity}`}
            <span className="ml-2">{name}</span>
            <span
              role="button"
              aria-label="Filter"
              aria-hidden="true"
              className="ml-2 link-text"
              onClick={() => updateFilterAndSearch({ ...filter, contentId: [sr.contentId] }, history)}
            >
              <MagnifyIcon size="10" />
            </span>
            <span
              role="button"
              aria-label="Filter"
              aria-hidden="true"
              className="ml-2 link-text"
              // eslint-disable-next-line max-len
              onClick={() => updateFilterAndSearch({ ...filter, sansContentId: filter.sansContentId.concat(sr.contentId) }, history)}
            >
              <CloseCircle size="10" />
            </span>
          </div>
          {
            assignments.map((asgn, i) => {
              const { id: asgnId, contentIdentifiableId } = asgn;
              return (
                <div key={`asgn${asgnId}`} className="ml-4">
                  <span className="mr-2">{i + 1})</span>
                  { contentIdentifiableId }
                  {
                    contentIdentifiableId
                    && shipment.status === 'unfulfilled'
                    && (
                      <Link className="ml-1" to={`/orders/assignments/${asgnId}/remove`}>
                        <b>x</b>
                      </Link>
                    )
                  }
                  {
                    !contentIdentifiableId
                    && (shipment.userFlag || shipment.userUpdatesPending)
                    && <DisabledLink id={asgnId} userFlag={shipment.userFlag} />
                  }
                  {
                    !contentIdentifiableId
                    && shipment.status === 'unfulfilled'
                    && !shipment.userFlag
                    && !shipment.userUpdatesPending
                    && (
                      <Link
                        to={`/orders/assignments/${asgnId}/assign`}
                        className={shipment.userFlag && 'disabled-link'}
                      >
                        Assign
                      </Link>
                    )
                  }
                </div>
              );
            })
          }
        </div>
      );
    })
  )

  createRows = (list) => (
    list.map((salesShipment) => {
      const {
        id,
        trackingNumber,
        carrierServiceCode,
        name,
        status,
        userFlag,
        userUpdatesPending,
        orderTime,
        addressValidation,
        country,
        orderSource: {
          code: orderSourceCode,
          updateResponse: orderSourceUpdateResponse,
        },
      } = salesShipment;

      let displayCancel;
      let displayHold;
      let displayAcknowledge;
      if (status === 'unfulfilled' || status === 'on_hold') {
        displayCancel = (
          <Link to={`/orders/sales-shipments/${id}/cancel`}>
            Cancel
          </Link>
        );

        if (status === 'unfulfilled' || status === 'on_hold') {
          displayHold = (
            <Link to={`/orders/sales-shipments/${id}/hold`}>
              {status === 'on_hold' ? 'Remove ' : ''}Hold
            </Link>
          );
        }

        if (userUpdatesPending && userFlag !== 'cancellation') {
          displayAcknowledge = (
            <Link to={`/orders/sales-shipments/${id}/acknowledge-updates`}>
              Acknowledge Updates
            </Link>
          );
        }
      }

      let displayTracking;
      let displayProcessed;
      if ((status === 'unfulfilled' || status === 'fulfilled') && isFullyAssigned(salesShipment)) {
        if (carrierServiceCode === 'pick_up') {
          displayTracking = (
            <span>
              <b>Pick Up</b>
              <PackingSlip id={id} />
            </span>
          );
        } else {
          displayTracking = <ShippingColumn shipment={salesShipment} />;
        }

        if ((trackingNumber || carrierServiceCode === 'pick_up') && status !== 'fulfilled') {
          displayProcessed = (
            <span>
              <Link className="ml-1" to={`/orders/sales-shipments/${id}/mark-as-processed`}>
                Mark As Processed
              </Link>
            </span>
          );
        }
      }

      const { filter, updateFilterAndSearch, history } = this.props;

      return (
        <tr key={randInt()}>
          <td>
            <div><b className="mr-3">ID</b>{id}</div>
            <div><b className="mr-3">Time</b>{orderTime}</div>
            <div><b className="mr-3">Status</b>{formatStatus(status)}</div>
            <div>
              <b className="mr-3">Recipient</b>
              {name}
              <span
                role="button"
                aria-label="Filter"
                aria-hidden="true"
                className="ml-2 link-text"
                onClick={() => updateFilterAndSearch({ ...filter, search: name }, history)}
              >
                <MagnifyIcon size="10" />
              </span>
              <span className="ml-1">
                {
                  addressValidation.status !== 'not_validated'
                  && (
                    <AddressValidationTooltip
                      id={`addr-${id}`}
                      validation={addressValidation}
                    />
                  )
                }
              </span>
            </div>
            <div>
              <b className="mr-3">Source</b>
              {orderSourceCode}
              {
                // eslint-disable-next-line no-nested-ternary
                status === 'fulfilled' && orderSourceCode === 'ship_station'
                  ? (
                    orderSourceUpdateResponse?.success
                      ? <span style={{ color: '#3F9E6B' }}> Updated</span>
                      : <span style={{ color: 'red' }}> Update Failed</span>
                  )
                  : null
              }
            </div>
            <div>
              <b className="mr-3">Country</b>
              {country}
            </div>
          </td>
          <td>
            {
              userFlag === 'hold'
              && <div className="my-1"><Badge color="secondary">Hold Requested</Badge></div>
            }
            {
              userFlag === 'cancellation'
              && <div className="my-1"><Badge color="danger">Cancel Requested</Badge></div>
            }
            {
              userUpdatesPending && userFlag !== 'cancellation'
              && <div className="my-1"><Badge color="warning">Updates Pending</Badge></div>
            }
          </td>

          <td>{this.formatContents(salesShipment)}</td>
          <td>{displayTracking}</td>
          <td>
            <Link to={`/orders/sales-shipments/${id}`}>
              Show
            </Link>
            { displayCancel && <span className="mx-2">|</span> }
            { displayCancel }
            { displayHold && <span className="mx-2">|</span> }
            { displayHold }
            { displayProcessed && <span className="mx-2">|</span> }
            { displayProcessed }
            { displayAcknowledge && <span className="mx-2">|</span> }
            { displayAcknowledge }
          </td>
        </tr>
      );
    })
  )

  buildBadgeList = (filter, defaultFilter) => {
    const {
      contentId,
      processedDate,
      processedAfterDate,
      processedBeforeDate,
      search,
      status,
      sansContentId,
    } = filter;
    const {
      processedDate: defaultProcessedDate,
      processedAfterDate: defaultProcessedAfterDate,
      processedBeforeDate: defaultProcessedBeforeDate,
      search: defaultSearch,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('contentId'):
          if (contentId && Array.isArray(contentId) && contentId.length) {
            badgeList.push(
              {
                order: 3,
                title: 'Contents',
                descFilterable: 'shipmentProfiles',
                filterKey: key,
              },
            );
          }
          break;
        case ('sansContentId'):
          if (sansContentId && Array.isArray(sansContentId) && sansContentId.length) {
            badgeList.push(
              {
                order: 4,
                title: 'Exclude Contents',
                descFilterable: 'shipmentProfiles',
                filterKey: key,
              },
            );
          }
          break;
        case ('processedDate'):
          if (processedDate && processedDate !== defaultProcessedDate) {
            badgeList.push(
              {
                order: 5,
                title: 'Fulfillment Date',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('processedAfterDate'):
          if (processedAfterDate && processedAfterDate !== defaultProcessedAfterDate) {
            if (!processedBeforeDate) {
              badgeList.push(
                {
                  order: 5,
                  title: 'Fulfilled After',
                  desc: filter.processedAfterDate,
                  filterKey: key,
                },
              );
            }
          }
          break;
        case ('processedBeforeDate'):
          if (processedBeforeDate && processedBeforeDate !== defaultProcessedBeforeDate) {
            if (processedAfterDate && processedAfterDate !== defaultProcessedAfterDate) {
              badgeList.push(
                {
                  order: 5,
                  title: 'Fulfilled Between',
                  desc: `${filter.processedAfterDate} and ${filter.processedBeforeDate}`,
                  filterKey: ['processedBeforeDate', 'processedAfterDate'],
                },
              );
            } else {
              badgeList.push(
                {
                  order: 5,
                  title: 'Fulfilled Before',
                  desc: filter[key],
                  filterKey: 'processedBeforeDate',
                },
              );
            }
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc: filter[key].replace(/_/g, ' '),
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        newButtonText="New Sales Order"
        exportButton
        pageTitle="Sales Shipments"
        searchPlaceholder="Search by ID or Address..."
        heads={
          [
            { key: 'info', name: 'Info' },
            { key: 'flags', name: '' },
            { key: 'contents', name: 'Contents' },
            { key: 'tracking', name: 'Shipping Info' },
            { key: 'actions', name: 'Actions' },
          ]
        }
        filterForm={
          [
            {
              type: 'RadioButtonGroup',
              groupLabel: 'Shipment Status',
              filterKey: 'status',
              options: [
                { value: 'unfulfilled', label: 'Pending' },
                { value: 'fulfilled', label: 'Fulfilled' },
                { value: 'canceled', label: 'Canceled' },
                { value: 'on_hold', label: 'On Hold' },
                { value: '', label: 'All' },
              ],
            },
            {
              type: 'MultiSelect',
              groupLabel: 'Contents',
              filterKey: 'contentId',
              placeholder: 'Filter by Contents...',
              optionsKey: 'shipmentProfiles',
            },
            {
              type: 'MultiSelect',
              groupLabel: 'Exclude Contents',
              filterKey: 'sansContentId',
              placeholder: 'Exclude by Contents...',
              optionsKey: 'shipmentProfiles',
            },
            {
              type: 'DateTime',
              groupLabel: 'Fulfillment Date',
              filterKeys: {
                on: 'processedDate',
                before: 'processedBeforeDate',
                after: 'processedAfterDate',
              },
              filterTypes: ['on', 'before', 'after', 'range'],
            },
          ]
        }
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.index.salesShipments.filter,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateFilterAndSearch: updateApiFilterAndSearch,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesShipmentsIndexContainer));
