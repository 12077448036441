/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 16,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const ImageField = ({
  files: allFiles,
  setFieldValue,
  label,
  formKey,
  errors,
}) => {
  const file = allFiles[formKey];

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      // Grab the file we and merge a preview
      const f = acceptedFiles[0];
      f.preview = URL.createObjectURL(acceptedFiles[0]);
      setFieldValue('files', { ...allFiles, [formKey]: f });
    },
  });

  const removeFile = () => {
    setFieldValue('files', { ...allFiles, [formKey]: {} });
  };

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(file.preview);
  }, [file]);

  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{label}</span>
      <div className="form__form-group-field">
        <div
          {...getRootProps({ className: 'dropzone' })}
          style={{
            width: '96%',
            height: '50px',
            border: '2px dashed gray',
            borderRadius: '5px',
          }}
        >
          <input {...getInputProps()} />
          { !file.name && <p style={{ textAlign: 'center' }}>Drop file here or click to select!</p> }
          { file.name && <p style={{ textAlign: 'center' }}>{file.name}</p> }
        </div>
      </div>
      <aside style={thumbsContainer}>
        { file.name && (
        <div>
          <div
            role="none"
            style={{
              color: 'white',
              float: 'right',
              cursor: 'pointer',
              marginRight: '10px',
            }}
            onClick={() => removeFile(file.name)}
          >
            x
          </div>
          <div style={thumb} key={file.name}>
            <div style={thumbInner}>
              <img
                src={file.preview}
                style={img}
                alt=""
              />
            </div>
          </div>
        </div>
        )}
      </aside>
      <aside>
        <p style={{ color: 'red' }}>{errors[formKey]}</p>
      </aside>
    </div>
  );
};

export default ImageField;
