/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import { getFilterables } from '../../redux/reducers/authReducer';
import setResourceData from '../../helpers/setResourceData';
import CarrierColumns from './components/index/ShippingColumn/CarrierService/CarrierColumns'

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = ({ history: domHistory, match: { params } }) => {
  // const options = mailingContainers.map((m) => ({ label: m.name, value: m.id }));
  const resourceData = setResourceData(domHistory, params);
  const { resourceExtension, id } = resourceData;
  return (
    <Container>
      <ShowContainer fetchUrl={`/${resourceExtension}/${id}/shipping_estimates`}>
        {
          ({list, shipment}) => {
            if (!id) return null;
            const initialValues = { id };
            return (
              <FormWrapper
                initialValues={initialValues}
                validationSchema={validationSchema}
                formUrlSuffix={`/${id}/set_carrier_service`}
                formTitle="Shipment Carrier Service Form"
                successModalTitle="Carrier Service Updated!"
                successModalMessage="This page will be reloaded!"
                formSubTitle={`Select carrier service for Shipment #${id}`}
                submitButtonText="Set Carrier Service"
                successUrl="/orders/sales-shipments"
              >
                <FormSectionWrapper>
                  <Row style={{ width: '100%' }}>
                    {console.log(shipment)}
                    <div className="ml-4 text-white"><b>Internal Notes:</b> {shipment?.orderSource?.internalNotes || 'N/A'}</div><br />
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <div className="ml-4 text-white"><b>Customer Notes:</b> {shipment?.orderSource?.customerNotes || 'N/A'}</div><br />
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <div className="ml-4 text-white"><b>Requested Shipping Service:</b> {shipment?.orderSource?.requestedShippingService || 'N/A'}</div><br />
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <div className="ml-4 text-white"><b>Service Code:</b> {shipment?.orderSource?.serviceCode || 'N/A'}</div>
                  </Row> 
                  <Row style={{ width: '100%' }}>
                    {list?.length ? <CarrierColumns shippingEstimates={list} /> : null}
                  </Row>
                </FormSectionWrapper>
              </FormWrapper>
            );
          }
        }
      </ShowContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  mailingContainers: getFilterables(state).mailingContainers,
});

Form.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  mailingContainers: PropTypes.arrayOf(PropTypes.object),
};

Form.defaultProps = {
  mailingContainers: [],
};

export default withRouter(connect(mapStateToProps)(Form));
