import formatter from '../../../../../../helpers/currencyHelper';
// export const stringAmounts = ({
//   confirmationAmount,
//   insuranceAmount,
//   otherAmount,
//   shippingAmount,
// }) => {
//   const shipping = `Shipping: ${formatter.format(shippingAmount.amount)}`;
//   const insurance = insuranceAmount.amount ? `<br />Insurance: ${formatter.format(insuranceAmount.amount)}` : '';
//   // eslint-disable-next-line max-len
// eslint-disable-next-line max-len
//   const confirmation = confirmationAmount.amount ? `<br />Confirmation: ${formatter.format(confirmationAmount.amount)}` : '';
//   const other = otherAmount.amount ? `<br />Other: ${formatter.format(otherAmount.amount)}` : '';
//   return shipping + insurance + confirmation + other;
// };

export const sumAmounts = (props) => {
  const {
    confirmationAmount,
    insuranceAmount,
    otherAmount,
    shippingAmount,
  } = props;
  return (
    (confirmationAmount?.amount || 0)
    + (insuranceAmount?.amount || 0)
    + (otherAmount?.amount || 0)
    + (shippingAmount?.amount || 0)
  );
};

export const toTitleCase = (str) => {
  try {
    return str.replaceAll('_', ' ').replace(
      /\w\S*/g,
      (txt) => (
        txt === 'am'
          ? 'AM'
          : txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      ),
    );
  } catch (_errors) {
    return str;
  }
};

export const carrierToTitleCase = (str) => {
  if (!str) return '';
  const tail = str.substring(str.indexOf('_') + 1);
  return toTitleCase(tail);
};

export const sortedPackages = (packages) => (
  packages.sort((a, b) => {
    if (sumAmounts(a) > sumAmounts(b)) return 1;
    if (sumAmounts(a) < sumAmounts(b)) return -1;
    return 0;
  })
);

export const packagesPricingToolTip = (packages) => {
  const list = packages.map(({ packageType, ...p }) => (
    `${toTitleCase(packageType)} - ${formatter.format(sumAmounts(p))}`));
  return list.join('<br />');
};

export const sortServiceByMin = (estimates) => (
  estimates.sort((a, b) => {
    if (sumAmounts(sortedPackages(a.packages)[0]) > sumAmounts(sortedPackages(b.packages)[0])) return 1;
    if (sumAmounts(sortedPackages(a.packages)[0]) < sumAmounts(sortedPackages(b.packages)[0])) return -1;
    return 0;
  })
);
