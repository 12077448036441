/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import FormWrapper from '../../shared/components/form/FormWrapper';

const Form = ({ match: { params } }) => (
  <Container>
    <FormWrapper
      initialValues={{}}
      formUrlSuffix={`/${params.id}/print_return_label`}
      formTitle="Print Label Confirmation"
      formSubTitle={`Please confirm that you would like to print a RETURN label for Shipment #${params.id}`}
      successModalTitle="Return Label Printed!"
      successModalMessage="You'll be sent back to the Shipment index page"
      submitButtonText="Confirm Print"
      successUrl="/orders/sales-shipments"
    />
  </Container>
);

export default withRouter(connect()(Form));
